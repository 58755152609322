import Web3 from "web3";
import provider from "./provider";


let web3 = '';

if(window.ethereum) {
  web3 = new Web3(window.ethereum);
} else {
  web3 = new Web3(provider);
 
}



export default web3;