import React, { Component } from "react";
import '../App.css';
import web3 from '../web3';
import abi from '../Json/timelock';
import factory from '../Json/factory.js';
import ercabi from '../Json/erc20.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class TwoPopup extends Component {

    state = {
        accounts: [],
        timelock:'',
        myContract:[],
        amount:'',
        address:'',
        token:'',
        owner:'',
        tokenName:'',
        erc:'',
        tokenBalance:'',
        myBalance:'',
        time:''
    };

    async componentDidMount() {
        const accounts = await web3.eth.getAccounts();
        const myContract = await factory.methods.getContractByAddress(accounts[0]).call();
        const address = myContract[2];
        const timelock = await new web3.eth.Contract(abi, address);
        const token = await timelock.methods.token().call();
        const amount = this.state.amount;
        const erc = await new web3.eth.Contract(ercabi, token);
        const owner = await timelock.methods.owner().call();
        const tokenName = await erc.methods.name().call();
        const tokenBalance = await erc.methods.balanceOf(myContract[2]).call();
        const myBalance = await erc.methods.balanceOf(accounts[0]).call();
       
        this.setState({accounts,timelock,myContract,amount, address, token, owner, tokenName,erc,tokenBalance,myBalance});
    }

    onRelease = async (event) => {
      event.preventDefault();
      const address = this.state.address;
      const timelock = this.state.timelock;
      const owner = this.state.owner;
      
      if(address.length > 0) {
        if(owner === this.state.accounts[0]) {
          if(this.state.tokenBalance > 0) {
            await timelock.methods.release().send({
              from: this.state.accounts[0],
            });
          } else {
            toast.error("the contract doesn't have tokens");
          }
        } else {
          toast.error('this is not your contract Mr.!');
        }
      } else {
        toast.error('address not valid');
      }
    }
  


    onTransfer = async (event) => {
      event.preventDefault();
      const address = this.state.address;
      const owner = this.state.owner;
      const erc = this.state.erc;
      const amount = this.state.amount;

      if(address.length > 0) {
        if(owner === this.state.accounts[0]) {
          if(amount > 0) {
            if(this.state.myBalance > amount) {

              await erc.methods.transfer(address, amount).send({
                from: this.state.accounts[0],
              }).on('receipt', async function(receipt) {
                            
                const hash = receipt.transactionHash;
                const hashed = await web3.eth.getTransactionReceipt(hash);
                toast.success(<a href={'https://www.bscscan.com/tx/' + hashed.transactionHash} className="alert-link">check your transaction on BSCSCAN</a>);
                
              }).catch((err) => {
                toast.error("Failed: " + err.message);
              });
            } else {
              toast.error('not enough token balance!');
            }
          } else {
            toast.error('please enter a valid number');
          }
        } else {
          toast.error('this is not your contract Mr.!');
        }
      } else {
        toast.error('address not valid');
      }
    }

  

    onTime = async (event) => {
      event.preventDefault();
      const timelock = this.state.timelock;
      const oldtime = await timelock.methods.releaseTime().call();
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(oldtime);
      let someDate = new Date(this.state.time);
      someDate = someDate.getTime() / 1000;

      if(new Date(this.state.time) > new Date(d)) {
        if(new Date(this.state.time) > new Date()) {
          if(this.state.time !== undefined) {

            await timelock.methods.releaseTime(someDate.toString()).send({
              from: this.state.accounts[0],
            }).on('receipt', async function(receipt) {
                          
              const hash = receipt.transactionHash;
              const hashed = await web3.eth.getTransactionReceipt(hash);
              toast.success(<a href={'https://www.bscscan.com/tx/' + hashed.transactionHash} className="alert-link">check your transaction on BSCSCAN</a>);
              
            }).catch((err) => {
              toast.error("Failed with error: " + err.message);
            });
          } else {
            toast.error("please choose a date and time");

          }
        } else {
          toast.error("new time has to be more than current time!");
        }
      } else {
        toast.error("new time has to be more than old time");

      }
    }


    render() {

      return (
        <div className='zeroPopup'>
          <div className='zeropopup_inner'>
            <img onClick={this.props.closeTwoPopup} alt="close" className="closezero" src={require('../images/close.png')} />
            <p>You can transfer tokens to your timelock contract any time and any amount of your choice until withdraw. 
              you can transfer directly from here, from your own wallet, or using the contract address within a transfer function inside your own contract.
            </p>

            <div className="transfer-form">
            {(this.state.accounts[0]) === undefined ? (
              <div id="loading"></div>
              ) : (
                <><p className="red">please send only {this.state.tokenName} to your timelock</p>
                <p><b>timelock balance: </b>{this.state.tokenBalance + " " +this.state.tokenName}</p></>
            )}
                <form onSubmit={this.onTransfer}>
                <input 
                          name="amount"
                          className="input-pop"
                          type="text"
                          placeholder="amount"
                          value={this.state.amount}
                          onChange= {event => this.setState({ amount: event.target.value })}
                      /> 
                  <button className="btn-transfer" disabled={this.state.accounts[0] === undefined}>Transfer</button>
                  </form>
            </div>

            <div className="time-form">
                <p><b>you can also extend withdraw time</b></p>
                <form onSubmit={this.onTime}>
                  <input 
                            name="time"
                            className="input-pop"
                            type="datetime-local"
                            placeholder="new time"
                            value={this.state.time}
                            onChange= {event => this.setState({ time: event.target.value })}
                        /> 
                  <button className="btn-transfer" disabled={this.state.accounts[0] === undefined}>Extend Time</button>
                </form>
            </div>

            <button className="btn-release" style={this.state.tokenBalance <= 0 ? {backgroundColor:'red'} : {}} onClick={this.onRelease} disabled={this.state.accounts[0] === undefined}>Withdraw</button>
            <img alt="logo" className="logoZero" src={require('../images/guardian-logo-s.png')} />

          </div>
          <ToastContainer/>
        </div>
      );
    }
    
  }

  export default TwoPopup;
