import React, { Component } from 'react';
import './App.css';
import presale from './Json/presale';
import token from './Json/token.js';
import factory from './Json/factory.js';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import Popup from './Popups/Popup';
import ZeroPopup from './Popups/ZeroPopup';
import OnePopup from './Popups/OnePopup';
import TwoPopup from './Popups/TwoPopup';
import web3 from './web3';
import provider from './provider';
import InfoPopup from './Popups/infoPopup';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Routes, Route, Link } from 'react-router-dom';
import TokenName from './timelocks/Zero';
import { ReleaseTime } from './timelocks/Zero';
import Token1Name from './timelocks/One';
import { Release1Time } from './timelocks/One';
import Token2Name from './timelocks/Two';
import { Release2Time } from './timelocks/Two';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';


class App extends Component {


  state = {
    accounts: [],
    balance: '',
    message: '',
    value: '',
    weiRaised: '',
    cap: '',
    percentage: 0,
    capReached: '',
    individualCap: '',
    showPopup: false,
    showInfoPopup: false,
    showTimelockpopup: false,
    showZeroPopup: false,
    showOnePopup: false,
    showTwoPopup: false,
    preAddress:'',
    tokensLeft:'',
    sliced:'',
    chainId:'',
    bnb:'',
    bnbContribution:0,
    contribution:0,
    indBNB:'',
    tokenfactory:'',
    beneficiaryfactory:'',
    releasetimefactory:'',
    timelock:'',
    myContract:[],
    limit:'',
    loading:false,
    loadingAcc:false,
    warning:false  };

  async componentDidMount() {
    const accounts = await web3.eth.getAccounts();
    accounts[0] = accounts[0] ? accounts[0] : '0x0000000000000000000000000000000000000000';
    const balance = await token.methods.balanceOf(accounts[0]).call();
    const sliced = balance.substring(0, balance.length - 9);
    const weiRaised = await presale.methods.weiRaised().call();
    const cap = await presale.methods.cap().call();
    const individualCap = await presale.methods.individualCap().call();
    const indBNB =  await web3.utils.fromWei(individualCap, 'ether');
    const percentage = (weiRaised*100/cap).toPrecision(3);
    const capReached = await presale.methods.capReached().call();
    const showPopup = false;
    const showInfoPopup = false;
    const showZeroPopup = false;
    const showOnePopup = false;
    const showTwoPopup = false;
    const loading = false;
    const loadingAcc = false;
    const warning = false;
    const preAddress = presale.options.address;
    const tokensLeft = await token.methods.balanceOf(preAddress).call();
    const chainId = await web3.eth.getChainId();
    const myBnb = await web3.eth.getBalance(accounts[0]);
    const bnb = web3.utils.fromWei(myBnb, 'ether');
    const contribution = await presale.methods.getContribution(accounts[0]).call();
    const bnbContribution =  web3.utils.fromWei(contribution, 'ether');
    // tokenlock constants
    const myContract = await factory.methods.getContractByAddress(accounts[0]).call();
    const limit = await factory.methods.getContractsAmountByAddress(accounts[0]).call();



    
    // Subscribe to accounts change
    await provider.on('accountsChanged', async () => {
      window.location.reload();
    });

    //chain change
    await provider.on('chainChanged', async () => {
      if(chainId !== 56) {
        await web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(56) }],
          });
        }
     window.location.reload();

    });



    // Subscribe to accounts change
    if(window.ethereum) {
      await window.ethereum.on('accountsChanged', async () => {
        window.location.reload();
      });
    

      await window.ethereum.on('chainChanged', async () => {
        if(chainId !== 56) {
            await web3.currentProvider.request({
              method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(56) }],
              });
            }
         window.location.reload();

        });
      }



    this.setState({ balance, accounts, weiRaised, cap, percentage, capReached, individualCap, showPopup, preAddress
      , tokensLeft, showInfoPopup, showZeroPopup, sliced, chainId, bnb, bnbContribution, contribution, indBNB,
       myContract,limit,showOnePopup,showTwoPopup, loading, loadingAcc, warning });
}


//popups

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  toggleInfoPopup() {
    this.setState({
      showInfoPopup: !this.state.showInfoPopup
    });
  }

  toggleZeroPopup() {
    this.setState({
      showZeroPopup: !this.state.showZeroPopup
    });
  }

  toggleOnePopup() {
    this.setState({
      showOnePopup: !this.state.showOnePopup
    });
  }


  toggleTwoPopup() {
    this.setState({
      showTwoPopup: !this.state.showTwoPopup
    });
  }


//convert big numbers

  numFormatter(num) {
    if(num > 999 && num < 1000000){
        return (num/1000) + 'K'; // convert to K for number from > 1000 < 1 million 
    }else if(num >= 1000000 && num < 1000000000){
        return (num/1000000) + 'M'; // convert to M for number from > 1 million 
    }else if(num >= 1000000000 && num < 1000000000000) {
        return (num/1000000000) + 'B';
    }else if(num >= 1000000000000 && num < 1000000000000000) {
        return (num/1000000000000) + 'T';
    }else if(num >= 1000000000000000) {
        return (num/1000000000000000) + 'P';
    }else if(num <= 900 && num > 0){
        return num; // if value < 1000, nothing to do
    }
  }


//shorten account
  cutAcc(acc) {
    if(this.state.accounts[0]) {
    return acc.substring(0,5) + '...' + acc.substring(acc.length-3,acc.length);
    }
  }

// create new timelock
  onCreate = async (event) => {
    event.preventDefault();
      if(this.state.limit <= 2) { 
          if(await web3.utils.isAddress(this.state.tokenfactory) === true) {
            if(await web3.utils.isAddress(this.state.beneficiaryfactory) === true)  {
              if(new Date(this.state.releasetimefactory) > new Date()) {
                  if(this.state.bnb > 0.025) {

                      this.setState({ message: 'waiting for confirmation...'});

                      let someDate = new Date(this.state.releasetimefactory);
                      someDate = someDate.getTime() / 1000;
                      await factory.methods.create(this.state.tokenfactory,this.state.beneficiaryfactory,someDate.toString() ,this.state.accounts[0]).send({
                        from: this.state.accounts[0],
                        value: '25000000000000000'
                        
                      }).on('receipt', async function(receipt) {
                        
                        
                        document.getElementsByTagName('h3')[1].scrollIntoView();

                        window.location.reload();
                        
                      }).catch((err) => {
                        toast.error(err.message, {containerId: 'A'});
                      });
                  } else {
                    toast.error('your balance is: ' + this.state.bnb, {containerId: 'A'});
                  }
              } else {
                toast.error('date already passed!', {containerId: 'A'});
              }
          } else {
            toast.error('Not a valid receiver address', {containerId: 'A'});
          }
        } else {
          toast.error('not a token contract!', {containerId: 'A'});
        }  
      } else {
        toast.error('You have reached the maximum limit!', {containerId: 'A'});
      }
      this.setState({ message: ''});
  }


  max = async (event) => {
    event.preventDefault();
    this.setState({ value: 5 });

  }

  changeNetwork = async (event) => {
    event.preventDefault();
      try {
        await web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(56) }],
          }).then(() => {    window.location.reload();
          });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await web3.currentProvider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x38",
                  chainName: "BSC mainnet",
                  rpcUrls: ["https://bsc-dataseed.binance.org/"],
                },
              ],
            });
          } catch (error) {
            toast.error(error.message, {containerId: 'A'});
          }
        }
    
    }
    window.location.reload();
  }

  // buy tokens from presale
  onSubmit = async (event) => {
    event.preventDefault();
    if(this.state.capReached !== true) {
        if(this.state.value >= 0.05 && this.state.value <= 5) {
              if(this.state.value < this.state.bnb) {
                if((this.state.bnbContribution + this.state.value) < this.state.indBNB ) {
                  this.setState({ loading: true })
                  try {
                    await presale.methods.buyTokens(this.state.accounts[0]).send({
                      from: this.state.accounts[0],
                      value: web3.utils.toWei(this.state.value, 'ether')
                    }).on('receipt', function(receipt){
                      window.location.reload();
                    });
                  } catch(e) {
                    console.log(e.message);
                  }
                  this.setState({ loading: false })

                } else {
                  toast.error('You have exceeded the maximum buy! you can now only purchase ' + (this.state.indBNB - this.state.bnbContribution) + ' and less', {containerId: 'A'});
                }
              } else {
                toast.error('Your BNB balance is only ' + this.state.bnb , {containerId: 'A'});
              }
        } else {
          toast.error('Please enter a value between 0.05 and 5', {containerId: 'A'});
        }
    } else {
        toast.error('Presale Ended!', {containerId: 'A'});
    }
  }  


//connect wallet
onWallet= async (event) => {
  event.preventDefault();
  if(window.ethereum) {
    this.setState({ loadingAcc: true });
    try {
    // trigger ethereum wallets inside wallet browser
    await window.ethereum.request({ method: "eth_requestAccounts" }).then(() => {window.location.reload()});
  } catch(e) {
    console.log(e.message);
    this.setState({ loadingAcc: false });
  }
  }  else {
    this.setState({ loadingAcc: true });
    window.location.reload();

    try {
      await provider.enable().then(() => {window.location.reload()});
       

    } catch(e) {
      console.log(e.message);
      this.setState({ loadingAcc: false });

    }
  }
  window.location.reload();
} 


// disconnects wallet
  ondis() {
    if(WalletConnectProvider) {
        provider.disconnect();
      window.location.reload();
    } else { 
      window.localStorage.clear();
      window.location.reload(); 
      
    }
  }

 

render() {


    return (

      <div className="App">
          <div className="tooltiptext" style={this.state.chainId !== 56 && this.state.chainId !== undefined ? {} : {display:'none'}}>Please Connect to BSC</div>
        <div className='navbar'>
          <ul>
            <li className='logo'><Link to="/"><img src={require('./images/guardian-logo-s.png')} alt="Logo"></img></Link></li>
            <li className='nextToLogo'><Link to="/">Home</Link></li>
            <li className='nextToLogo'><Link to="/Presale">Presale</Link></li>
            <li className='nextToLogo'><Link to="/TimeLock">TimeLock</Link></li>
            <li className='buttontop' style={this.state.accounts[0] && this.state.accounts[0] !== '0x0000000000000000000000000000000000000000' ? {display:'none'} : {}}><button className="buttonconnect" onClick={this.onWallet} >{(this.state.loadingAcc) === true ? (
                                <>Connecting..
                              </>
                                ) : (
                                <>Connect</>
                                )}</button></li>
            <li className='buttontop' style={this.state.accounts[0] && !window.ethereum ? {} : {display:'none'}}><button className="buttonconnect" onClick={this.ondis}>Disconnect</button></li>
            <li className='acc' style={this.state.accounts[0] && this.state.accounts[0] !== '0x0000000000000000000000000000000000000000' ? {}  : {display:'none'} }>{this.cutAcc(this.state.accounts[0])}</li>
            <li className='logo-bsc'><img src={require('./images/bsc.png')} onClick={this.changeNetwork} alt="bsc"></img></li>
          </ul>
        </div>
        <div className='smallnavbar'>
          <ul>
            <li className='logo'><Link to="/"><img src={require('./images/guardian-logo-s.png')} alt="Logo"></img></Link></li>
            <li className="burger">
              <div onClick={this.togglePopup.bind(this)}>
              <div className='menu-icon'></div>
              <div className='menu-icon'></div>
              <div className='menu-icon'></div>
                </div>
            </li>
            <li className='buttontop' style={this.state.accounts[0] && this.state.accounts[0] !== '0x0000000000000000000000000000000000000000' ? {display:'none'} : {}}><button className="buttonconnect" onClick={this.onWallet} >Connect</button></li>
            <li className='buttontop' style={this.state.accounts[0] && !window.ethereum ? {} : {display:'none'}}><button className="buttonconnect" onClick={this.ondis}>Disconnect</button></li>
            <li className='acc' style={this.state.accounts[0] && this.state.accounts[0] !== '0x0000000000000000000000000000000000000000' ? {} : {display:'none'}}>{this.cutAcc(this.state.accounts[0])}</li>
            <li className='logo-bsc'><img src={require('./images/bsc.png')} onClick={this.changeNetwork} alt="bsc"></img></li>
          </ul>
        </div>

        <Routes>
          <Route path='/' element={<div>
            <div className='home'>
              <div className='top-divs'>
                <div className='top-left-div'>
                    <h1>Guardiansale</h1>
                    <h3>Launchpad Protocol</h3>
                    <p className='blue-text'>Decentralized, Smart Contracts Based</p>
                    <p><span className='blue-square' ><Link to="/Presale">Launchpad</Link></span><span className='blue-border'><Link to="/Presale">Buy GST</Link></span></p> 
                </div>

                <div className="top-right-div">
                    <div className="backg">
                      <div className="planet">
                        <img src={require('./images/guardian-logo-xl.png')} alt='logo'></img>
                      </div>
                      <div className="stars">
                        <div className="s1"></div>
                        <div className="s2"></div>
                        <div className="s3"></div>
                        <div className="s4"></div>
                        <div className="s5"></div>
                        <div className="s6"></div>
                      </div>
                      <div className="an">
                        <div className="tank"></div>
                        <div className="astro">
                            <div className="helmet">
                              <div className="glass">
                                <div className="shine"></div>
                              </div>
                            </div>
                            <div className="dress">
                              <div className="c">
                                <div className="btn1"></div>
                                <div className="btn2"></div>
                                <div className="btn3"></div>
                                <div className="btn4"></div>
                              </div>
                            </div>
                            <div className="handl">
                              <div className="handl1">
                                <div className="glovel">
                                  <div className="thumbl"></div>
                                  <div className="b2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="handr">
                              <div className="handr1">
                                <div className="glover">
                                  <div className="thumbr"></div>
                                  <div className="b1"></div>
                                </div>
                              </div>
                            </div>
                            <div className="legl">
                              <div className="bootl1">
                                <div className="bootl2"></div>
                              </div>
                            </div>
                            <div className="legr">
                              <div className="bootr1">
                                <div className="bootr2"></div>
                              </div>
                            </div>
                            <div className="pipe">
                              <div className="pipe2">
                                <div className="pipe3"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                <div className='card-div'>
                    <ul className='squares'>
                      <li><span>Estimated Liquidity</span><br/>$751K+</li>
                      <li><span>Projects</span><br/>1+</li>
                      <li><span>Participants</span><br/>800+</li>
                      <li><span>Value Locked</span><br/>$6,7M+</li></ul>
                </div>

                <div className='centerd'>
                  <h1 id='centerd-l-h1'>Why Guardiansale?</h1>
                  <p id='centerd-l-p'>Safer then ever!<br/>
                  Easy user experience, low fees, smart contracts based protocol and <br/> only one-click to reach decentralized launchpad technologies.</p>
                  <h1 id='centerd-s-h1'>Why Guardiansale?</h1>
                  <p id='centerd-s-p'>Safer then ever!<br/>
                  Easy user experience, low fees, smart contracts based protocol and only one-click to reach decentralized launchpad technologies.</p>
                  
                  <div>
                      <div className="wrapper">
                          <div className="base">
                              <div className="base-bottom">
                              </div>
                              <div className="lock-inside-top">
                              </div>
                              <div className="lock-inside-bottom">
                              </div>
                          </div>
                          <div className="lock-cirlce">
                              <div className="lock-circle-inside">
                              </div>
                          </div>
                          <div className="lock-box">
                          </div>
                      </div>
                  </div>
                </div>

                <div className='box-in-center'>
                  <img className="check-l" src={require('./images/check.png')} alt="check"></img>
                  <img className="check-s" src={require('./images/check-s.png')} alt="check"></img>
                </div>
              
              
                <div className='divs'>
                  <div className='divs-left-write'>
                    <h1>Guardiansale Protocol <span className='live' >Live</span></h1>
                    <p className='p-left'>Easy user experience, low fees, smart contract based protocol and one-click to reach<br/> decentralized
                      launchpad technologies.</p>
                  </div>
                  <div className='divs-right-img'>
                    <img className='circle-img' src={require('./images/search.png')} alt="logo"></img>
                  </div>
                </div>

                <div className='divs'>
                  <div className='divs-right-write'>
                    <h1 className='align-right'>Guardiansale TimeLock <span className='live' >Live</span></h1>
                    <p className='p-right'>Lock your liquidity/Tokens in your own Timelock contract that can receive tokens<br/> the way you choose, and as many times you want!</p>
                  </div>
                  <div className='divs-left-img'>
                    <img className='circle-img' src={require('./images/lock.png')} alt="logo"></img>
                  </div>
                </div>

                <div className='divs'>
                  <div className='divs-left-write'>
                    <h1>Guardiansale Token Mint <span className='soon' >Soon</span></h1>
                    <p className='p-left'>Mint a customized token the way you want and without any coding knowledge!</p>
                  </div>
                  <div className='divs-right-img'>
                    <img className='circle-img' src={require('./images/hammer.png')} alt="logo"></img>
                  </div>
                </div>

                <div className='divs'>
                  <div className='divs-right-write'>
                    <h1 className='align-right'>Guardiansale Fair Launch <span className='soon' >Soon</span></h1>
                    <p className='p-right'>Launch your token with fair launch protocol and self-liquidity provider!</p>
                  </div>
                  <div className='divs-left-img'>
                    <img className='circle-img' src={require('./images/rocket.png')} alt="logo"></img>
                  </div>
                </div>

                <div className='divs'>
                  <div className='divs-left-write'>
                    <h1>Guardiansale DxDrop <span className='soon' >Soon</span></h1>
                    <p className='p-left'>Create your airdrop with the security of smart contracts!</p>
                  </div>
                  <div className='divs-right-img'>
                    <img className='circle-img' src={require('./images/drop.png')} alt="logo"></img>
                  </div>
                </div>

             

              </div>
            </div>} />

          <Route path='/Presale' element={<>
            <Helmet>
              <title>Guardiansale Token Presale</title>
              <meta name='description' content='Everything you need is here. technology, marketing, security and low fees. GST is more than a token. please check our whitepaper for more information.'
              />
              <meta name="keywords" content="presale, token presale, ido, coin presale, crowdsale, token crowdsale, guardiansale presale, guardiansale"/>
              <link rel='canonical' href='/Presale'/>
            </Helmet>
            <header className="App-header">
              <div className='row'>
                <div className='leftcolumn'>
                      <div className='upperleft'>
                        <h2>GST Presale</h2>
                        <p>Everything you need is here. technology, marketing, security and low fees. GST is more than a token.
                          please check our whitepaper for more information.
                        </p>
                        <div className='logolist'>
                          <ul>
                            <li><a href="https://github.com/guardiansale/files/blob/main/GST%20Whitepaper.pdf" target = "_blank" rel="noopener noreferrer" className='a'>Whitepaper</a>  <a href="https://github.com/guardiansale/files/blob/main/GST%20Audit.pdf" target = "_blank" rel="noopener noreferrer" className='a'>Audit</a></li>
                            <li className='logos'><a href="https://www.facebook.com/guardiansalelaunchpad" target = "_blank" rel="noopener noreferrer"><img src={require('./images/facebook-icon.png')} alt="facebook"></img></a></li>
                            <li className='logos'><a href="https://www.instagram.com/guardiansale" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/instagram-icon.png')} alt="instagram"></img></a></li>
                            <li className='logos'><a href="https://t.me/guardiansaleEN" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/telegram-icon.png')} alt="telegram"></img></a></li>
                            <li className='logos'><a href="https://www.tiktok.com/@guardiansale" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/tiktok-icon.png')} alt="tiktok"></img></a></li>
                            <li className='logos'><a href="https://twitter.com/guardiansale" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/twitter-icon.png')} alt="twitter"></img></a></li>
                            <li className='logos'><a href="https://www.youtube.com/channel/UC6HJgDP4mWBg8oq3QZIMaJA" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/youtube-icon.png')} alt="youtube"></img></a></li>
                          </ul>
                        </div>
                      </div>

                      <div className='buy'>
                        <p className='tinytext'><span className='floatleft'>Reached: {parseFloat(web3.utils.fromWei(this.state.weiRaised, 'ether')).toFixed(2)} BNB </span><span className='floatright'>Goal: ---- BNB</span></p>
                          <div className="pdiv">
                            <Progress percent={parseFloat(this.state.percentage).toFixed(1)}   theme={{active: {color:"#0F8047", trailColor: '#bbbbbb'}}} />
                          </div>
                        <form onSubmit={this.onSubmit}>
                            <div className="formdiv">
                              <label>Amount: </label>
                              <br></br>
                              <div className='relative'>
                                <input 
                                    placeholder="0.00"
                                    pattern="^\d*(\.\d{0,2})?$"
                                    type="number"
                                    min="0.05"
                                    max="5"
                                    step="0.01"
                                    value={this.state.value}
                                    onChange= {event => this.setState({ value: event.target.value })}
                                /> 
                               {(this.state.loading) === true ? (
                                <><div id='loadingg' className='absolute-load'></div>
                                <p className='red padding-margin'>this can take up to 45 seconds</p></>
                                ) : (
                                <b className='absolute' onClick={this.max}>MAX</b>
                                )}
                                
                                </div>
                                <button className="buttonsend" disabled={this.state.accounts[0] === undefined || this.state.chainId !== 56 || (new Date(1669899600000)) > (new Date())} >BUY</button>
                                <ul className='underbuy'>
                                <li><span className='floatleft'>Status:</span> <span className='floatright'>{this.state.capReached === false && (new Date(1669899600000)) <= (new Date()) ?  <b className='bluetext'>In progress</b> : this.state.capReached === true ? <span>Ended</span> : <span>Upcoming</span>}</span></li>
                                <li><span className='floatleft'>Sale type:</span><span className='floatright bluetext'>Public</span></li>
                                <li><span className='floatleft'>Minimum buy:</span><span className='floatright'>0.05 BNB</span></li>
                                <li><span className='floatleft'>Maximum buy:</span><span className='floatright'>5 BNB</span></li>
                                <li><span className='floatleft'>You have:</span><span className='floatright balance'>{this.state.sliced > 0 ? this.numFormatter(this.state.sliced) + '..' : 0} GST</span></li>
                                </ul>
                              </div>
                          </form>
                      </div>

                      <ul className='percentages'>
                        <label className='tokenomics'>Tokenomics</label>
                        <li className='alignLeft'>Presale</li>
                        <li className='percentDiv'><Progress percent={6} theme={{active: {color:"#23a2c9", trailColor: '#bbbbbb'}}} /></li>
                        <li className='alignLeft'>Liquidity</li>
                        <li className='percentDiv'><Progress percent={4} theme={{active: {color:"#23a2c9", trailColor: '#bbbbbb'}}} /></li>
                        <li className='alignLeft'>Vested For Listing</li>
                        <li className='percentDiv'><Progress percent={15} theme={{active: {color:"#23a2c9", trailColor: '#bbbbbb'}}} /></li>
                        <li className='alignLeft'>Locked</li>
                        <li className='percentDiv'><Progress percent={75} theme={{active: {color:"#23a2c9", trailColor: '#bbbbbb'}}} /></li>
                        <li className='alignLeft'>Marketing</li>
                        <li className='percentDiv'><Progress percent={0} theme={{active: {color:"#23a2c9", trailColor: '#bbbbbb'}}} /></li>
                        <li className='alignLeft'>Developing</li>
                        <li className='percentDiv'><Progress percent={0} theme={{active: {color:"#23a2c9", trailColor: '#bbbbbb'}}} /></li>
                        <button className='buttonask' onClick={this.toggleInfoPopup.bind(this)} >Click to Join Airdrop</button>
                      </ul>
                </div>
                <div className='rightcolumn'>
                  <div className='paragraph'>
                  <h1>Guardiansale Token <span className='live' style={this.state.capReached === false && (new Date(1669899600000)) <= (new Date()) ? {} : {display:'none'}}>Live</span><span className='ended' style={this.state.capReached === true && (new Date(1669899600000)) < (new Date()) ? {} : {display:'none'}}>Ended</span></h1>
                    <p>Welcome to our presale! We are offering 4% total fees and high momentum.
                      Our token has 4 fundamental functions. With every transaction, holders are sharing
                      1%, 1% goes to liquidity pool, 1% buyback and 1% for marketing. Our presale sends the BNB directly to a unique timelock contract
                      that already locks all tokens as well before the presale begins and for 365 days, and creates LP inside the contract itself.
                       the contract has no other way out for tokens or BNB other than pancakeswap liquidity pool.
                       We have only hard cap. When the cap is reached, our timelock will open the pancakeswap LP the next day at 18:00 UTC.
                    </p>
                  </div>

                  <div id="table">
                    <p>
                      <span>Presale Address: </span>
                      <span className='righttd'><a href={'https://www.bscscan.com/address/0x74dFb2DE72C9B450C6aA39BD6DB16d33Bcb0Cd80'} target = "_blank" rel="noopener noreferrer" >0x74dFb2DE72C9B450C6aA39BD6DB16d33Bcb0Cd80</a></span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Token Name:</span>
                      <span className='righttd'>Guardiansale Token</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Token Symbol:</span>
                      <span className='righttd'>GST</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Token Decimals:</span>
                      <span className='righttd'>18</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Token Address: </span>
                      <span className='righttd'><a href={'https://www.bscscan.com/address/0xb8106748796cDC9257B1315FC772eD2184032c06'} target = "_blank" rel="noopener noreferrer" >0xb8106748796cDC9257B1315FC772eD2184032c06</a></span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Total Supply:</span>
                      <span className='righttd'> 100.000.000.000 GST</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Tokens for presale:</span>
                      <span className='righttd'>6.000.000.000 GST</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Tokens for liquidity:</span>
                      <span className='righttd'>4.000.000.000 GST</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Presale rate:</span>
                      <span className='righttd'> 3.000.000 GST/BNB</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Listing rate:</span>
                      <span className='righttd'> 2.857.142 GST/BNB</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Initial Market Cap (estimate)</span>
                      <span className='righttd'> ----</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Hard Cap</span>
                      <span className='righttd'>---- BNB</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Presale Start Time</span>
                      <span className='righttd'>----</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Listing on:</span>
                      <span className='righttd'>Pancakeswap</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Liquidity percentage:</span>
                      <span className='righttd'>70%</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span>Liquidity Lock up Time:</span>
                      <span className='righttd'> 365 days with the rest of the tokens supply</span>
                    </p>
                    <hr></hr>
                    <p>
                      <span className='lastone'>Listing Time:</span>
                      <span className='lastone righttd'> ----</span>
                    </p>

                  </div>

                  
                  <div id="table2" >
                    
                          <p>Presale Address: <span className='bluetext address'><a href={'https://www.bscscan.com/address/0x74dFb2DE72C9B450C6aA39BD6DB16d33Bcb0Cd80'} target = "_blank" rel="noopener noreferrer" >0x74dFb2DE72C9B450C6aA39BD6DB16d33Bcb0Cd80</a></span></p>
                          <hr></hr>
                      
                          <p>Token Name: <span className='bluetext'>Guardiansale Token</span></p>
                          <hr></hr>
                    
                          <p>Token Symbol:<span className='bluetext'> GST</span> </p>
                          <hr></hr>
                    
                          <p>Token Decimals: <span className='bluetext'>18</span> </p>
                          <hr></hr>
                    
                          <p>Token Address: <span className='bluetext address'><a href={'https://www.bscscan.com/address/0xb8106748796cDC9257B1315FC772eD2184032c06'} target = "_blank" rel="noopener noreferrer" >0xb8106748796cDC9257B1315FC772eD2184032c06</a></span></p>
                          <hr></hr>

                          <p>Total Supply: <span className='bluetext'>100.000.000.000 GST</span></p>
                          <hr></hr>

                          <p>Tokens for presale: <span className='bluetext'>6.000.000.000 GST</span></p>
                          <hr></hr>

                          <p>Tokens For Liquidity:<span className='bluetext'> 4.000.000.000 GST </span></p>
                          <hr></hr>

                          <p>Presale Rate: <span className='bluetext'> 3.000.000 GST/BNB </span></p>
                          <hr></hr>

                          <p>Listing Rate: <span className='bluetext'> 2.857.142 GST/BNB</span> </p>
                          <hr></hr>

                          <p>Initial Market Cap (estimate): <span className='bluetext'> ----</span></p>
                          <hr></hr>

                          <p>Hard Cap: <span className='bluetext'>---- BNB</span></p>
                          <hr></hr>

                          <p>Presale Starting Time:<span className='bluetext'> ---- </span></p>
                          <hr></hr>

                          <p>Listing On: <span className='bluetext'>Pancakeswap </span></p>
                          <hr></hr>

                          <p>Liquidity percent: <span className='bluetext'>70% </span></p>
                          <hr></hr>

                          <p>Liquidity Lockup Time: <span className='bluetext'> 365 days with the rest of the tokens supply</span></p>
                          <hr></hr>

                          <p>Listing time:<span className='bluetext'> ----</span></p>
                      </div>
                </div>
              </div>
            </header>
          </>
          } />


          <Route path='/TimeLock' element={<>
            <Helmet>
              <title>Guardiansale Timelock Protocol</title>
              <meta name='description' content='Lock your liquidity/Tokens in your own Timelock contract that can receive tokens the way you choose, and as many times you want!'
              />
              <meta name="keywords" content="timelock, token timelock, liquidity timelock"/>
              <link rel='canonical' href='/TimeLock'/>
            </Helmet>
            <div className='time-backg'>
                <div className="stars-s"></div>
                <div className="twinkling-s"></div>  
                <div>
                <div className='top-margin-10'>
                <h2 className='h1-glow'>Step One</h2>
                <p className='h1-glow'>first let's create a new timelock contract</p>
                <p className='h1-glow'>each wallet can create up to 3 timelocks</p>
                <p className='h1-glow'>Service fee: 0.025 BNB</p>
                </div>
                
                <div className='create-lock'>
                  <form onSubmit={this.onCreate}>
                    <h2>Create a New Lock </h2>
                    <p className='left-bold'>Why use Guardiansale locker ?</p>
                    <ul>
                      <li>Lowest fees in the market</li>
                      <li>Smart contract based locking system</li>
                      <li>Safer than ever</li>
                      <li>Easy to use</li>
                    </ul>

                    <p className='label'>Token or pancake LP pair Address<span>*</span> </p>
                    <input 
                        name="token"
                        type="text"
                        placeholder="token"
                        value={this.state.tokenfactory}
                        onChange= {event => this.setState({ tokenfactory: event.target.value })}
                    /> 
                    <p className='label'>Enter the receiving wallet address<span>*</span> </p>
                    <input 
                        name="beneficiary"
                        type="text"
                        placeholder="beneficiary"
                        value={this.state.beneficiaryfactory}
                        onChange= {event => this.setState({ beneficiaryfactory: event.target.value })}
                    /> 
                    <p className='label'>Lock until time<span>*</span></p>
                    <input 
                        name="releasetime"
                        type="datetime-local"
                        placeholder="releasetime"
                        value={this.state.releasetimefactory}
                        onChange= {event => this.setState({releasetimefactory: event.target.value })}
                    /> 
                    <p className='factory-a' >Service fees: 0.025 BNB</p>

                    <h3>{this.state.message}</h3>

                    <button className="buttonsend" disabled={this.state.accounts[0] === undefined || this.state.accounts[0] === '0x0000000000000000000000000000000000000000' || this.state.chainId !== 56}>Create</button>
                  </form>
                </div>
             
                <h2 className='h1-glow'>Step Two</h2>
                <p className='h1-glow'>transfer token/LP token, extend contract time and withdraw tokens</p>
                <p className='h1-glow'>no service fee</p>
              
            
                <div className='contracts-container'>
                  <h3 id='timelocks'>My Timelock Contracts: </h3>
                  {(this.state.myContract[0]) === undefined ? (
                          <><h2>your timelocks will appear here...</h2>
                    <div className="dots-container">
                      <div className="pulse-dot pulse-dot-1"></div>
                      <div className="pulse-dot pulse-dot-2"></div>
                      <div className="pulse-dot pulse-dot-3"> </div>
                    </div></>
        
      
                    ) : (
                      <></>
                  )}
                    <div className='contracts'  style={this.state.myContract[0] === undefined ? {display:'none'} : {}}>
                        <div>
                        {(this.state.myContract[0]) === undefined ? (
                          <></>
                          ) : (
                            <h3>Token Name: <TokenName /></h3>
                        )}
                        {(this.state.myContract[0]) === undefined ? (
                          <></>
                          ) : (
                            <h4>Locked until: <ReleaseTime /></h4>
                        )}
                        </div>
                        <a href={'https://www.bscscan.com/address/' + this.state.myContract[0]}>{this.state.myContract[0]}</a>
                        <div className='btn-contract-container'>
                          <button className="btn-contract"  onClick={this.toggleZeroPopup.bind(this)}>Transfer Tokens | Extend Time | Withdraw</button>
                        </div>
                    </div>

                    <div className='contracts'  style={this.state.myContract[1] === undefined ? {display:'none'} : {}}>
                        <div>
                        {(this.state.myContract[1]) === undefined ? (
                          <></>
                          ) : (
                            <h3>Token Name: <Token1Name /></h3>
                        )}
                        {(this.state.myContract[1]) === undefined ? (
                          <></>
                          ) : (
                            <h4>Locked until: <Release1Time /></h4>
                        )}
                        </div>
                      <a href={'https://www.bscscan.com/address/' + this.state.myContract[1]}>{this.state.myContract[1]}</a>
                      <div className='btn-contract-container'>
                        <button className="btn-contract"  onClick={this.toggleOnePopup.bind(this)}>Transfer Tokens | Extend Time | Withdraw</button>
                      </div>
                    </div>

                    <div className='contracts'  style={this.state.myContract[2] === undefined ? {display:'none'} : {}}>
                        <div>
                        {(this.state.myContract[2]) === undefined ? (
                          <></>
                          ) : (
                            <h3>Token Name: <Token2Name /></h3>
                        )}
                        {(this.state.myContract[2]) === undefined ? (
                          <></>
                          ) : (
                            <h4>Locked until: <Release2Time /></h4>
                        )}
                        </div>
                        <a href={'https://www.bscscan.com/address/' + this.state.myContract[2]}>{this.state.myContract[2]}</a>
                        <div className='btn-contract-container'>
                          <button className="btn-contract"  onClick={this.toggleTwoPopup.bind(this)}>Transfer Tokens | Extend Time | Withdraw</button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </>
          }></Route>

        </Routes>

        <div className="footer">
                <ul>
                  <li>Guardiansale</li>
                  <li className='footerlogo'><a href="https://www.facebook.com/guardiansalelaunchpad" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/facebook-icon.png')} alt="facebook"></img></a></li>
                  <li className='footerlogo'><a href="https://www.instagram.com/guardiansale" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/instagram-icon.png')} alt="instagram"></img></a></li>
                  <li className='footerlogo'><a href="https://t.me/guardiansaleEN" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/telegram-icon.png')} alt="telegram"></img></a></li>
                  <li className='footerlogo'><a href="https://www.tiktok.com/@guardiansale" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/tiktok-icon.png')} alt="tiktok"></img></a></li>
                  <li className='footerlogo'><a href="https://twitter.com/guardiansale" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/twitter-icon.png')} alt="twitter"></img></a></li>
                  <li className='footerlogo'><a href="https://www.youtube.com/channel/UC6HJgDP4mWBg8oq3QZIMaJA" target = "_blank" rel="noopener noreferrer" ><img src={require('./images/youtube-icon.png')} alt="youtube"></img></a></li>

                </ul>
        </div>

            {this.state.showPopup ?  
              <Popup  
                text='X'  
                closePopup={this.togglePopup.bind(this)}  
              />  
              : null  
            }  

            {this.state.showInfoPopup ?  
              <InfoPopup  
                text='X'  
                closePopup={this.toggleInfoPopup.bind(this)}  
              />  
              : null  
            }  

            {this.state.showZeroPopup ?  
              <ZeroPopup  
                text='X'  
                closeZeroPopup={this.toggleZeroPopup.bind(this)}  
              />  
              : null  
            }  

            {this.state.showOnePopup ?  
              <OnePopup  
                text='X'  
                closeOnePopup={this.toggleOnePopup.bind(this)}  
              />  
              : null  
            } 

            {this.state.showTwoPopup ?  
              <TwoPopup  
                text='X'  
                closeTwoPopup={this.toggleTwoPopup.bind(this)}  
              />  
              : null  
            } 
              <ToastContainer
              enableMultiContainer
              containerId={'A'}/>
      </div>
    );
  }
}

export default App;
