import React, { Component } from "react";
import '../App.css';
import { Link } from "react-router-dom";


class Popup extends Component {

    render() {
     
      return (
        <div className='popup' onClick={this.props.closePopup}>
          <div className='popup_inner'>
          
            <div className="list">

            <p><Link to="/">Home</Link></p>
            <hr></hr>
            <p><Link to="/Presale">Presale</Link></p>
            <hr></hr>
            <p><Link to="/TimeLock">TimeLock</Link></p>
            </div>
          </div>
        </div>
      
      );
    }
  }
  
  export default Popup;