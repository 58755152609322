import React, { Component } from "react";
import '../App.css';
import web3 from '../web3';
import abi from '../Json/timelock';
import factory from '../Json/factory.js';
import ercabi from '../Json/erc20';









class Token2Name extends Component {

    state = {
        accounts: [],
        timelock:'',
        myContract:[],
        address:'',
        token:'',
        tokenName:'',
        erc:''




    };

    async componentDidMount() {
        const accounts = await web3.eth.getAccounts();
        const myContract = await factory.methods.getContractByAddress(accounts[0]).call();
        const address = myContract[2];
        const timelock = await new web3.eth.Contract(abi, address);
        const token = await timelock.methods.token().call();
        const erc = await new web3.eth.Contract(ercabi, token);
        const tokenName = await erc.methods.name().call();
        console.log(tokenName);
       



        this.setState({accounts,timelock,myContract, address, token, tokenName,erc});
    }




  

    render() {
     
      return (
        <span>{this.state.tokenName}</span>
      );
    }
    
  }

  export default Token2Name;

  class Release2Time extends Component {

    state = {
        accounts: [],
        timelock:'',
        myContract:[],
        address:'',
        releaseTime:'',
        d:''




    };

    async componentDidMount() {
        const accounts = await web3.eth.getAccounts();
        const myContract = await factory.methods.getContractByAddress(accounts[0]).call();
        const address = myContract[2];
        const timelock = await new web3.eth.Contract(abi, address);
        const releaseTime = await timelock.methods.releaseTime().call();

      
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(releaseTime);
  
       



        this.setState({accounts,timelock,myContract, address, releaseTime,d});
    }




  

    render() {
     
      return (
        <span>{(this.state.d).toString().substring(0,33)}</span>
      );
    }
    
  }

  export {Release2Time};