import React, { Component } from "react";
import '../App.css';

class InfoPopup extends Component {

    render() {
     
      return (
        <div className='infopopup'>
          <div className='infopopup_inner'>
            <img onClick={this.props.closePopup} alt="close" className="closeinfo" src={require('../images/close.png')} />
            <div className="info">
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdoUeDxOgWZ1ScSSHzCNMg2471WZ_NvY7clWx_2JrTGi2EAHg/viewform" 
              width="100%" height="700" frameBorder="0" marginHeight="0" marginWidth="0" title='iframe'>Loading…</iframe>
            </div>
          </div>
        </div>
      );
    }
  }
  
  export default InfoPopup;